import { initializeApp } from 'firebase/app';
// import { getAuth } from "firebase/auth";
// import { getFirestore, serverTimestamp } from 'firebase/firestore';
import { getAnalytics, logEvent } from 'firebase/analytics';

const config = {
  apiKey: "AIzaSyCcCEFCdDAmjYPv7cL3CGGMpmQPsvrrEwA",
  authDomain: "cream-city-app.firebaseapp.com",
  projectId: "cream-city-app",
  storageBucket: "cream-city-app.appspot.com",
  messagingSenderId: "610834517627",
  appId: "1:610834517627:web:a2444d6990493f3d4c1cde",
  measurementId: "G-8DFD720KVM",
  // timestampsInSnapshots: true,
};

const app = initializeApp(config);
const analytics = getAnalytics(app);
// export const db = getFirestore(app);
// export const auth = getAuth(app);
// export const timestamp = () => serverTimestamp();
export const log = (event) => logEvent(analytics, event);

