import { useState, useEffect } from "react";
import { useNavigate, NavLink } from "react-router-dom";
import { Dialog } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import Modal from '../Modal/Modal';
import { navigation } from "../../utils/navigation";
import headerLogo from '../../images/logo-cream-city-cards-v1_1.png';

const cssHelper = ({ isActive, isPending }) => {
  const baseCss = 'rounded-md px-3 py-2 text-sm font-medium';
  const activeCss = 'bg-cream-city-red-900  text-white';
  const defaultCss = 'text-cream-city-cream-100 hover:bg-gray-700 hover:text-white';

  return isActive || isPending ? `${baseCss} ${activeCss}` : `${baseCss} ${defaultCss}`;
};

const cssHelperMobile = ({ isActive }) => {
  const baseCss = '-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white hover:bg-gray-800';
  const activeCss = 'border-1 border-dashed';

  return isActive ? `${baseCss} ${activeCss}` : `${baseCss}`;
};

export default function Header() {
  const navigate = useNavigate();
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [showBorder, setShowBorder] = useState(false);

  useEffect(() => {
    const element = document.getElementById('main');
    element.addEventListener('scroll', () => {
      if (element.scrollTop >= 5) {
        setShowBorder(true);
      } else {
        setShowBorder(false);
      }
    });
  }, []);

  return (
    <header className={`inset-x-0 top-0 z-50 p-1.5 ${showBorder ? 'border-b border-sky-500/30 shadow-md' : 'border-b-transparent'}`}>
      <nav className="flex items-center justify-between p-2 lg:px-8" aria-label="Global">
        <div className="flex lg:flex-1">
          <a
            href="/"
            className="-m-1.5 p-1.5"
          >
            <span className="sr-only">Cream City Cards & Collectibles</span>
            <img
              className="h-20 w-auto"
              src={headerLogo}
              alt="Cream City Cards & Collectibles"
            />
          </a>
        </div>
        <Modal open={modalOpen} setModalOpen={setModalOpen} />
        <div className="flex lg:hidden">
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-400"
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <div className="hidden lg:flex lg:gap-x-12">
          {
            navigation.routes.filter(item => item.header && item.visible).sort().map(item => (
              <NavLink
                key={`${item.name}-header-link`}
                to={item.href}
                target={item.external ? '_blank' : '_self'}
                className={cssHelper}
                onClick={() => {
                  if (!item.visible) {
                    setModalOpen(true);
                  }
                }}
                disabled={!!item.disabled}
              >
                {item.name}
              </NavLink>
            ))
          }
        </div>

        <div className="hidden lg:flex lg:flex-1 lg:justify-end">
          {
            navigation.routes.filter(item => item.action && item.visible).sort().map(item => (
              <button
                key={`${item.name}-action-link`}
                type="button"
                className="
                  inline-flex
                  items-center
                  gap-x-2
                  rounded-md
                  bg-red-500
                  px-3.5
                  py-2.5
                  text-sm
                  font-semibold
                  text-white
                  shadow-sm
                  hover:bg-red-400
                  focus-visible:outline
                  focus-visible:outline-2
                  focus-visible:outline-offset-2
                  focus-visible:outline-red-500
                "
                onClick={() => {
                  navigate(item.href);
                }}
                disabled={!!item.disabled}
              >
                {/* <HandRaisedIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" /> */}
                {item.name}
                {item.icon && <item.icon className="-mr-0.5 h-5 w-5" aria-hidden="true" />}
              </button>
            ))
          }
        </div>
      </nav>

      {/* MOBILE NAVIGATION */}
      <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
        <div className="fixed inset-0 z-50" />
        <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-gray-900 px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-white/10">
          <div className="flex items-center justify-between">
            <NavLink to="/" className="-m-1.5 p-1.5">
              <span className="sr-only">Andrew Ninneman for Union Grove Elementary School Board</span>
              <img
                className="h-20 w-auto"
                src={headerLogo}
                alt="Andrew Ninneman for School Board"
              />
            </NavLink>
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-gray-400"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/25">
              <div className="space-y-2 py-6">
                {
                  navigation.routes.filter(item => item.mobile && item.visible).sort().map(item => {
                    return (
                      <NavLink
                        key={`${item.name}-mobile`}
                        to={item.href}
                        target={item.external ? '_blank' : '_self'}
                        className={cssHelperMobile}
                        onClick={() => {
                          setMobileMenuOpen(false);
                          if (!item.visible) {
                            setModalOpen(true);
                          }
                        }}
                        disabled={!!item.disabled}
                      >
                        {item.name}
                      </NavLink>
                    );
                  })
                }
              </div>
              <div className="py-6">
                {
                  navigation.routes.filter(item => item.action && item.visible).sort().map(item => (
                    <button
                      key={`${item.name}-action-link-mobile`}
                      type="button"
                      className="
                        inline-flex
                        items-center
                        gap-x-2
                        rounded-md
                        bg-red-500
                        px-3.5
                        py-2.5
                        text-sm
                        font-semibold
                        text-white
                        shadow-sm
                        hover:bg-red-400
                        focus-visible:outline
                        focus-visible:outline-2
                        focus-visible:outline-offset-2
                        focus-visible:outline-red-500
                      "
                      onClick={() => {
                        navigate(item.href);
                        setMobileMenuOpen(false);
                        if (!item.visible) {
                          setModalOpen(true);
                        }
                      }}
                      disabled={!!item.disabled}
                    >
                      {/* <HandRaisedIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" /> */}
                      {item.name}
                      {item.icon && <item.icon className="-mr-0.5 h-5 w-5" aria-hidden="true" />}
                    </button>
                  ))
                }
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </header>
  );
}