import { Routes, Route } from 'react-router-dom';
import Home from './views/Home/Home';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import Background from './components/Background/Background';
import ComingSoon from './components/ComingSoon/ComingSoon';

export default function App() {
  return (
    <div className="bg-blue-800 relative isolate overflow-hidden">
      <div id="container" className="flex flex-col">
        <Background />
        <Header />

        <main id="main" className="flex-grow overflow-scroll">

          {/* =========== VIEW SWITCH =========== */}
          <Routes>
            {/* <Route path="/help" element={<Help />} /> */}
            {/* <Route path="/*" element={<Home />} /> */}
            <Route path="/*" element={<ComingSoon />} />
          </Routes>

          {/* =========== FOOTER =========== */}
          <Footer />

        </main>
      </div>
    </div>
  );
}