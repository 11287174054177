import logo from '../../images/logo-cream-city-cards-v1_1.png';
import './ComingSoon.scss';

export default function ComingSoon() {
  return (

    <div id="coming-soon" className="hero h-full">
      <div className="hero-content flex-col lg:flex-row pb-32">
        <div className="bg-cream-city-cream-600 p-9 mx-9 text-cream-city-dark-blue-900 rounded-lg">
          <h1 className="text-5xl font-bold">Coming Soon!</h1>
          <p className="py-6">
            We are a father-son duo looking to make the sports collecting experience fun and easy! We look forward to sharing our journey as we continue to grow. Please come back often to check on our progress.
          </p>
          <p className="py-6">
            For now, you can checkout our <a href="https://www.ebay.com/usr/creamcity.collectibles" target="_blank" rel="noreferrer">eBay Store - creamcity.collectibles</a>!
          </p>
        </div>
        <img
          src={logo}
          className="max-w-sm rounded-lg shadow-2xl mx-9"
          alt="Coming Soon!"
        />
      </div>
    </div>
  );
}
